module.exports = {
    config: {
        merchantId: 5,
        merchantShopName: "Roller Tooth Brush",
        merchantDomain: "https://rollertoothbrush.com",
        defaultMetaImage: "assets/images/meta-shop-picture.png",
        enabledLanguagues: ['fr'],
        defaultLanguage: 'fr',
        enabledCurrencies: ["EUR"],
        defaultCurrency: "EUR",
        enabledDeliveryCountry: ['fr'],
        nbProductsByPage: 6,
        shopEmail: "contact@rollertoothbrush.com",
        shopPhone: "+33 (0)7 83 33 24 60",
        stripeCountryAccount: 'FR',
        shopAddress: "2405 route des dolines CS 10065",
        shopPostalCity: "06902 Sophia Antipolis Cedex",
        shopCountry: "France",
        shopCountryCode: "FR",
        shopSlogan: {
            "fr": "La première brosse électrique à la technologie du rouleau",
            "en": "La première brosse électrique à la technologie du rouleau"
        },
        shopMapsUrl: "",
        socialMedias: {
            // facebook: "https://www.facebook.com/",
            // instagram: "https://www.instagram.com/"
        },
        footerLink: [
            // {
            //     "name": "FOOTER_DELIVERY",
            //     "url": "/delivery"
            // },
            {
                "name": "FOOTER_LNOTICE",
                "url": "/legal-notice"
            }
            // {
            //     "name": "FOOTER_TERMS",
            //     "url": "/terms-of-sales"
            // },
        ],
        menu: [
            {
                "name": "NAV_HOME",
                "path": "home",
                "submenu": []
            },
            {
                "name": "RTB_MENU_SCIENTIFIC_ANALYSIS",
                "path": "scientific-analysis",
                "submenu": []
            },
            {
                "name": "Nos brosses",
                "path": "choose-your-brush",
                "submenu": []
            },
            // {
            //     "name": "Produits",
            //     "path": "catalog",
            //     "submenu": []
            // },
            {
                "name": "RTB_MENU_TESTIMONIALS",
                "path": "testimonials",
                "submenu": []
            }
            // {
            //     "name": "RTB_MENU_OUR_VALUES",
            //     "path": "our-values",
            //     "submenu": []
            // },
            // {
            //     "name": "Contact",
            //     "path": "contact",
            //     "submenu": []
            // }
        ],
        mondialRelayCode: "BDTEST  ",
        mondialRelayColLivMod: "24R",
        stripeKey: "pk_test_51IDX9sFONvuSNpV2p5megIgnGWJsaTkgrafNYpojDwzmCZG7a6DRoIpcNJO0khC3RCUhV5jZwACA3BgYrqaQLgWq00Z3QL5eaq",
        stripeAccount: "",
        quoteDownloadEnabled: false,
        reviewEnabled: false,
        defaultCatalogSorting: 'RELEVANCE',
        enabledB2B: true,
        redirect: []
    }
}
